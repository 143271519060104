import { FaqIcon } from '@assets/icons';
import GraphUpDownArrowIcon from '@assets/icons/GraphUpArrow';
import useBreakpoint from '@hooks/useBreakpoint';
import clsx from 'clsx';
import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

interface CardProps {
  title: string;
  value: string | number;
  percentage: string;
  since: string;
  up: boolean;
  compareDescription?: string;
  backgroundColor: string;
}

const Card: React.FC<CardProps> = ({
  title,
  value,
  percentage,
  since,
  up,
  compareDescription = 'vs. yesterday',
  backgroundColor,
}) => {
  const { isMobile } = useBreakpoint();
  return (
    <BootstrapCard
      className="z-1 rounded-3 shadow-sm border-slight mb-3"
      style={{ background: backgroundColor }}
    >
      <BootstrapCard.Body>
        <div className="d-flex justify-content-between">
          <BootstrapCard.Title style={{ fontSize: '12px' }}>
            {title}
          </BootstrapCard.Title>
          <div className="d-flex justify-content-end ">
            <FaqIcon width={13} height={13} color="#b3b6ba" />
          </div>
        </div>
        <div
          className={clsx(
            'd-flex gap-1',
            isMobile ? 'flex-column align-items-start' : 'align-items-center',
          )}
        >
          <span className="fs-xl fw-bold me-2">{value}</span>
          <div className="d-flex gap-1 align-items-center">
            <GraphUpDownArrowIcon down={up !== true} />
            <span
              className={clsx(
                isMobile ? 'fs-xs' : 'fs-sm',
                up === true ? 'text-success' : 'text-danger',
              )}
            >
              {/* {Math.abs(percentage)}% */}
              {percentage}
            </span>
            <span className={clsx(isMobile ? 'fs-xxs' : 'fs-sm')}>{since}</span>
          </div>
        </div>
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

export default Card;
