import CloseIcon from '@assets/icons/CloseIcon';
import LinkIcon from '@assets/icons/LinkIcon';
import UserAvatar from '@components/UserAvatar';
import useAuth from '@hooks/useAuth';
import useBreakpoint from '@hooks/useBreakpoint';
import axiosInstance from '@utils/axios-service';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormControl,
  Modal,
  ModalTitle,
  Spinner,
} from 'react-bootstrap';
import UserJson from 'types/user.type';

type SubmitLinksModalProps = {
  open: boolean;
  onClose: () => void;
};
const SubmitLinksModal: React.FC<SubmitLinksModalProps> = ({
  open,
  onClose,
}) => {
  const { profiles } = useAuth();
  const { isMobile } = useBreakpoint();
  const [links, setLinks] = useState<string[]>(['']);
  const [linkText, setLinkText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [selectedProfile, setSelectedProfile] = useState<
    UserJson.Profile | undefined
  >(undefined);
  async function handleSubmit() {
    if (!selectedProfile) {
      setError('Please select profile');
      return;
    }
    const linksFromText = linkText.split('\n');
    const filtered = linksFromText.filter((link) => link.length > 0);

    if (filtered.findIndex((link) => !link || link === '') >= 0) {
      setError('All links is required');
      return;
    }
    setSubmitting(true);
    setError('');
    try {
      await axiosInstance.post('/api/profile/links/submit', {
        source_links: filtered,
        profile_id: selectedProfile?.id,
      });
      setLinks([]);
      onClose();
    } catch (err: any) {
      setError(
        err.response?.data?.errors
          ?.map((error: any) => error.source_link)
          ?.join(',') || '',
      );
    }
    setLinkText('');
    setSubmitting(false);
  }
  useEffect(() => {
    setSelectedProfile(profiles[0]);
  }, [profiles]);
  return (
    <Modal show={open} onHide={onClose} size="lg">
      <Modal.Header className="px-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold d-flex align-items-center gap-2">
          <LinkIcon />
          Submit links
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={onClose}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="p-5 pt-0">
        <Dropdown className="mb-4">
          <DropdownToggle
            className={
              'd-flex align-items-center justify-content-between gap-2'
            }
            variant="outline-light"
          >
            <div className="d-flex align-items-center gap-2">
              <UserAvatar imgUrl={selectedProfile?.picture} size={20} />
              {selectedProfile?.name}
            </div>
          </DropdownToggle>
          <DropdownMenu className="bg-white">
            {profiles.map((p, id) => (
              <DropdownItem
                key={id}
                className="d-flex align-items-center gap-2 py-2"
                onClick={() => setSelectedProfile(p)}
              >
                <UserAvatar imgUrl={p?.picture} size={20} colorIndex={id} />
                {p?.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {error && <Alert variant="danger">{error}</Alert>}
        <div style={{ minHeight: isMobile ? 200 : 400 }}>
          <FormControl
            as={'textarea'}
            className="px-4 h-full"
            size={isMobile ? undefined : 'lg'}
            placeholder="www.example.com"
            value={linkText}
            onChange={
              (e) => {
                console.log('e.target.value :>> ', e.target.value);
                setLinkText(e.target.value);
              }
              // setLinks(links.map((it, i) => (i === id ? e.target.value : it)))
            }
            style={{ minHeight: isMobile ? 300 : 350, lineHeight: 1.5 }}
            // onPaste={() => {
            //   if (id === links.length - 1) {
            //     setLinks([...links, '']);
            //   }
            // }}
          />
          <div className="d-flex align-items-center mb-3 d-none">
            <hr className="flex-grow-1" />
            <Button
              className="d-flex align-items-center justify-content-center rounded-circle cursor-pointer"
              style={{
                width: 26,
                height: 26,
                background: '#f7f3f3',
                color: '#bcc0c7',
              }}
              onClick={() => setLinks([...links, ''])}
            >
              +
            </Button>
            <hr className="flex-grow-1" />
          </div>
        </div>
        <Button
          className="w-100"
          size={isMobile ? undefined : 'lg'}
          onClick={handleSubmit}
          disabled={submitting}
        >
          {submitting && <Spinner size="sm" className="me-2" />}
          Save and Submit
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default SubmitLinksModal;
