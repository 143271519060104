import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import moment from 'moment';
import useBreakpoint from '@hooks/useBreakpoint';
import { DateRangePicker, DefinedRange, Range } from 'react-date-range';
import useAuth from '@hooks/useAuth';
import UserJson from 'types/user.type';
import UserAvatar from '@components/UserAvatar';
import clsx from 'clsx';

type DashboardFilterProps = {
  profile?: UserJson.Profile;
  setProfile: (p: UserJson.Profile | undefined) => void;
  selectedRange: Range;
  setSelectedRange: (range: Range) => void;
};

const DashboardFilter: React.FC<DashboardFilterProps> = ({
  profile,
  setProfile,
  selectedRange,
  setSelectedRange,
}) => {
  const { profiles } = useAuth();
  const [isCustom, setIsCustom] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [range, setRange] = useState<Range>(selectedRange);
  const { isMobile } = useBreakpoint();
  const [showProfilesModal, setShowProfilesModal] = useState(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);

  useEffect(() => {
    if (profiles.length === 1) {
      setProfile(profiles[0]);
    } else {
      setProfile(undefined);
    }
  }, [profiles]);

  return (
    <div className="d-flex gap-3">
      {!isMobile && (
        <>
          <Dropdown className="mb-4">
            <DropdownToggle
              className={
                'd-flex align-items-center justify-content-between gap-2'
              }
              variant="outline-light"
              size="sm"
            >
              <div className="d-flex align-items-center gap-2">
                {profile ? (
                  <>
                    <UserAvatar imgUrl={profile?.picture} size={20} />
                    {profile?.name}
                  </>
                ) : (
                  'All Profiles'
                )}
              </div>
            </DropdownToggle>
            <DropdownMenu className="bg-white">
              {profiles.length === 0 && (
                <DropdownItem
                  className="d-flex align-items-center gap-2 py-2"
                  disabled
                >
                  No Profiles
                </DropdownItem>
              )}
              {profiles.length > 1 && (
                <DropdownItem
                  className="d-flex align-items-center gap-2 py-2"
                  onClick={() => setProfile(undefined)}
                >
                  All Profiles
                </DropdownItem>
              )}
              {profiles.map((p, id) => (
                <DropdownItem
                  key={id}
                  className="d-flex align-items-center gap-2 py-2"
                  onClick={() => setProfile(p)}
                >
                  <UserAvatar imgUrl={p?.picture} size={20} colorIndex={id} />
                  {p?.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Dropdown className="mb-4" show={showMenu}>
            <DropdownToggle
              variant="outline-light"
              size="sm"
              onClick={() => {
                setRange(selectedRange);
                setShowMenu(true);
              }}
            >
              {moment(selectedRange.startDate).format('DD MMMM')} -{' '}
              {moment(selectedRange.endDate).format('DD MMMM')}
            </DropdownToggle>
            <DropdownMenu className="bg-white">
              <DateRangePicker
                onChange={(v: any) => {
                  setRange(v['range']);
                }}
                retainEndDateOnFirstSelection={false}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[range]}
                editableDateInputs={true}
                direction={isMobile ? 'vertical' : 'horizontal'}
              />
              <div className="d-flex justify-content-end gap-3 px-2">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => setShowMenu(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    setSelectedRange(range);
                    setShowMenu(false);
                  }}
                >
                  Apply
                </Button>
              </div>
            </DropdownMenu>
          </Dropdown>
        </>
      )}
      {isMobile && (
        <>
          <DropdownToggle
            className={
              'd-flex align-items-center justify-content-between gap-2 w-50 mb-4'
            }
            variant="outline-light"
            size="sm"
            onClick={() => setShowProfilesModal(true)}
          >
            <div className="d-flex align-items-center gap-2">
              {profile ? (
                <>
                  <UserAvatar imgUrl={profile?.picture} size={20} />
                  {profile?.name}
                </>
              ) : (
                'All Profiles'
              )}
            </div>
          </DropdownToggle>

          <DropdownToggle
            className="w-50 mb-4 justify-content-between d-flex align-items-center"
            variant="outline-light"
            size="sm"
            onClick={() => {
              setRange(selectedRange);
              setShowDateRangeModal(true);
            }}
          >
            {moment(selectedRange.startDate).format('DD MMM')} -{' '}
            {moment(selectedRange.endDate).format('DD MMM')}
          </DropdownToggle>
        </>
      )}
      <Modal
        show={showProfilesModal}
        className="bottom"
        onHide={() => setShowProfilesModal(false)}
        size="xl"
      >
        <ModalHeader closeButton>Profiles</ModalHeader>
        <ListGroup className="rounded-0 overflow-auto max-vh-50">
          {profiles.map((p, id) => (
            <ListGroupItem
              key={id}
              action
              className={clsx(
                'd-flex align-items-center gap-3 py-2',
                profile?.id === p.id && 'bg-light',
              )}
              onClick={() => {
                setProfile(p);
                setShowProfilesModal(false);
              }}
            >
              <UserAvatar imgUrl={p?.picture} size={40} colorIndex={id} />
              <div>
                <div>{p?.name}</div>
                <div className="text-primary">@{p.instagram_username}</div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Modal>
      <Modal
        show={showDateRangeModal}
        className="bottom "
        onHide={() => setShowDateRangeModal(false)}
      >
        <ModalHeader closeButton>Date range</ModalHeader>
        <div className="d-flex align-items-center flex-column">
          {!isCustom && (
            <>
              <div className="d-flex justify-content-between align-items-center w-100 px-3 py-2">
                <span>
                  {moment(range.startDate).format('DD MMMM')} -{' '}
                  {moment(range.endDate).format('DD MMMM')}
                </span>
                <Button
                  className="text-decoration-none"
                  variant="link"
                  onClick={() => setIsCustom(true)}
                >
                  Custom
                </Button>
              </div>
              <DefinedRange
                onChange={(v: any) => {
                  setRange(v['range']);
                }}
                ranges={[range]}
                className="w-100"
              />
            </>
          )}
          {isCustom && (
            <>
              <Button
                className="ms-auto me-2 mb-2 mt-2 text-decoration-none"
                variant="link"
                onClick={() => setIsCustom(false)}
              >
                Pre defined date
              </Button>
              <DateRangePicker
                onChange={(v: any) => {
                  setRange(v['range']);
                }}
                ranges={[range]}
                retainEndDateOnFirstSelection={false}
                moveRangeOnFirstSelection={false}
                editableDateInputs={true}
                direction={'vertical'}
              />
            </>
          )}
          <div className="d-flex w-100 justify-content-end gap-3 p-2">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setShowDateRangeModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setSelectedRange(range);
                setShowDateRangeModal(false);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardFilter;
